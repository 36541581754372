import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import onboardingSelectors from "@/pages/Questionary/model/selectors";
import {actions as onboardingActions} from "@/pages/Questionary/model/slice";
import {getAuthState} from "@/shared/model/auth/selectors";
import {actions} from "@/shared/model/auth/slice";
import {TPutUserPersonalOnModeration} from "@/shared/types/onboarding";
import ConfusedFaceImg from "@/shared/ui/assets/emoji/confused-face.png";
import {CustomButton} from "@/shared/ui/uikit/CustomButton/CustomButton";
import {Button, InputCode} from "@/shared/ui/uikit/Form";
import {Block, CloseButton} from "@/shared/ui/uikit/Layout";

import "./confirm-email.scss";

interface TProps {
	data?: TPutUserPersonalOnModeration
	onClose?: () => void
}

const ConfirmEmail: React.FC<TProps> = ({data, onClose}) => {
	const dispatch = useDispatch();
	const [hasError, setHasError] = useState(false);
	const userId = JSON.parse(localStorage.getItem("userId"));
	const {phone: {number}, firstName} = data;

	const {codeLoading, requestError} = useSelector(getAuthState);
	const {loading, error} = useSelector(onboardingSelectors.GET_STATE);

	useEffect(() => {
		setHasError(!!requestError);
	}, [requestError]);

	const submitCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		const code = event.target.value;
		const phone = number;
		const name = firstName;
		if (code.length === 4) {
			dispatch(actions.submitCode({code, phone, name}));
		} else {
			setHasError(false);
		}
	};

	const handleSubmit = () => {
		dispatch(onboardingActions.postUserPersonal(data));
	};

	const handleClose = () => {
		onClose();
		setHasError(false);
		localStorage.removeItem("session");
	};

	return (
		<Block className="confirm-email">
			<Block className="confirm-email__content">
				<h2 className="confirm-email__title">Введите код</h2>
				<p className="confirm-email__description">Отправили код на: <span className="confirm-email__text">{data.email}</span></p>
				<InputCode hasError={hasError}
					disabled={codeLoading}
					onChange={submitCode}
					className="confirm-email__field"
					errorMessage={requestError?.message}
					emoji={ConfusedFaceImg}
				/>
				<Button disabled={!userId}
					color="primary"
					onClick={handleSubmit}
					className="confirm-email__btn"
					isLoading={loading}
				>
					Войти
				</Button>
				<CloseButton onClick={handleClose}
					className="confirm-email__close-btn"
				/>
			</Block>
		</Block>
	);
};

export default React.memo(ConfirmEmail);
