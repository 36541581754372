import React from "react";

import "./spiner.scss";

/**
 * Тип для указания размера спиннера.
 */
export type SpinnerSize = "small" | "big" | "medium";

/**
 * @prop [className] Класс.
 * @prop [] Флаг отображения синего спиннера.
 * @prop [isInline] Флаг отображения в строке.
 * @prop [size] Размер.
 */
interface TProps {
	className?: string
	isInline?: boolean
	size?: SpinnerSize
	isWhite?: boolean
}

export const Spinner = ({
	className,
	isInline,
	isWhite = false,
	size = "big"
}: TProps) => {
	return (
		<div className={`loadSpinner ${isWhite ? "loadSpinner--white" : ""} ${isInline ? "inline" : ""} ${size} ${className}`}/>
	);
};
