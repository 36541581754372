import React from "react";
import ReactDOM from "react-dom";
import cls from "classnames";

import "./styles.scss";

export const ModalDialog = ({
	children,
	className,
	...divProps
}: React.PropsWithoutRef<JSX.IntrinsicElements["div"]>): React.ReactElement => {
	return ReactDOM.createPortal(
		<div className={cls("modal", "modal_open")} {...divProps}>
			<div className={cls("modal__content", className)}>
				{children}
			</div>
		</div>,
		document.body
	);
};
