import React from "react";

type TextColor =
  | "default"
  | "primary"
  | "base3"
  | "base4"
  | "error"
  | "not-active"
  | "disabled"
  | "body"
  | "action"
  | "warning"
  | "white";

interface Props {
	color: TextColor
	className?: string
}

export const Color = ({
	color = "default",
	className,
	children
}: Props &
React.PropsWithoutRef<JSX.IntrinsicElements["span"]>): React.ReactElement => {
	return (
		<span className={`typography__color--${color} ${className}`}>
			{children}
		</span>
	);
};
