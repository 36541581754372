import React, {useState} from "react";
import ReactDatePicker, {DatePickerProps, registerLocale} from "react-datepicker";
import {Controller} from "react-hook-form";
import {getMonth, getYear} from "date-fns";
import ru from "date-fns/locale/ru";

import CalendarIcon from "@/shared/ui/assets/calendar.png";
import {CalendarLeftArrowSvg, CalendarRightArrowSvg, CalendarSvg} from "@/shared/ui/assets/svg";
import {Block} from "@/shared/ui/uikit/Layout";

import {MONTHS, years} from "./CustomReactDatePicker.constants";
import {CustomReactDatePickerTProps} from "./CustomReactDatePicker.types";

import "react-datepicker/dist/react-datepicker.css";
import "./custom-react-datepicker.scss";

registerLocale("ru_RU", ru);
registerLocale("ru_RU", ru);

const CustomReactDatePicker: React.FC<DatePickerProps & CustomReactDatePickerTProps> = ({
	name,
	rules,
	control,
	defaultValue,
	label,
	readonly,
	className,
	disabled,
	isValid,
	errorMessage,
	dateFormat,
	placeholder,
	popperPlacement = "bottom-start",
	maxDate
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpenList = () => {
		setIsOpen(!isOpen);
	};

	const handleYear = (year, changeYear) => {
		changeYear(year);
		setIsOpen(false);
	};

	return (
		<Block className={`custom-react-datepicker ${className}`}>
			{label && <span className="custom-react-datepicker__label">{label}</span>}
			<Controller defaultValue={defaultValue}
				control={control}
				name={name}
				rules={rules}
				render={(props) => {
               	return (
						<ReactDatePicker {...props}
							className={`custom-react-datepicker__input ${isValid === false && "custom-react-datepicker__input--invalid"}`}
							placeholderText={placeholder}
							selected={props.value}
							dateFormat={dateFormat}
							dateFormatCalendar="MMMM"
							autoComplete="off"
							locale="ru"
							popperPlacement={popperPlacement}
							showPopperArrow={false}
							readOnly={readonly}
							disabled={disabled}
							aria-invalid={!isValid}
							renderCustomHeader={props => renderCustomHeader({...props, handleOpenList, isOpen, handleYear})}
							maxDate={maxDate}
							showIcon
							icon={<CalendarSvg />}
						/>
               	);
				}}
			/>
			{errorMessage && <span className="custom-react-datepicker__error-message">{errorMessage}</span>}
		</Block>
	);
};

const renderCustomHeader = ({
	date,
	changeYear,
	decreaseMonth,
	increaseMonth,
	handleOpenList,
	isOpen,
	handleYear
}) => {
	return (
		<Block className="custom-react-datepicker__header">
			<Block className="custom-react-datepicker__wrapper">
				<div className="custom-react-datepicker__btn" onClick={decreaseMonth}>
					<CalendarLeftArrowSvg className="custom-react-datepicker__arrow-icon custom-react-datepicker__arrow-icon--left" />
				</div>
				<span className="custom-react-datepicker__title">{MONTHS[getMonth(date)]}</span>
				<div className="custom-react-datepicker__btn" onClick={increaseMonth}>
					<CalendarRightArrowSvg className="custom-react-datepicker__arrow-icon custom-react-datepicker__arrow-icon--right" />
				</div>
			</Block>
			<Block className="custom-react-datepicker__select">
				{getYear(date)}
				<div className="custom-react-datepicker__select-btn" onClick={handleOpenList}>
					<CalendarLeftArrowSvg className="custom-react-datepicker__arrow-icon custom-react-datepicker__arrow-icon--down" />
				</div>
			</Block>
			{isOpen && <Block className="custom-react-datepicker__select-item-wrapper">
				{years.map((year) => (
					<div className="custom-react-datepicker__select-item"
						key={year}
						onClick={() => handleYear(year, changeYear)}
					>
						{year}
					</div>
				))
				}
			</Block>
			}
		</Block>
	);
};

export default React.memo(CustomReactDatePicker);
