import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {routePath} from "@/app/routes";
import {actions as profileActions} from "@/pages/Profile/model/slice";
import {getCalendarState} from "@/shared/model/calendar/selectors";
import {getPsychologistState} from "@/shared/model/psychologist/selectors";
import {actions} from "@/shared/model/psychologist/slice";
import {Button} from "@/shared/ui/uikit/Form";
import {Block} from "@/shared/ui/uikit/Layout/";
import {Spinner} from "@/shared/ui/uikit/Spinner/Spinner";
import ConnectToConferenceBanner from "@/widgets/ConnectToConferenceBanner";

import TelegramAlertBanner from "./TelegramAlertBanner/TelegramAlertBanner";
import Header from "./Header";
import {MobileMenuContent} from "./MobileMenuContent";
import Profile from "./Profile";

import "./styles.scss";

interface TProps {
	children: React.ReactNode
	onLogout: () => void
}

/**
 * Лэйаут для приватных роутов, который загружает информацию по психологу,
 * и, при необходимости, делает refresh accessToken.
 */
const ProfileBlock: React.FC<TProps> = ({children, onLogout}) => {
	const {profile} = useSelector(getPsychologistState);
	const {calendar} = useSelector(getCalendarState);
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isBannerOpen = sessionStorage.getItem("telegramBanner");

	useEffect(() => {
		if (!profile.loading && !profile.data) {
			dispatch(actions.getPsychologist());
		}
	}, []);

	useEffect(() => {
		if (profile.data?.telegram.length < 1 && !isBannerOpen) {
			setIsOpen(true);
		}
	}, [profile.data?.telegram, isBannerOpen]);

	const handleCloseTelegramBanner = useCallback(() => {
		setIsOpen(false);
		sessionStorage.setItem("telegramBanner", "close");
	}, [isOpen]);

	const handleAddTelegram = () => {
		setIsOpen(false);
		navigate(`${routePath.dashboard}/profile/edit`);
		dispatch(profileActions.SET_EDITABLE_PAGE("settings"));
	};

	return (
		<>
			{profile.data && calendar ? (
				<div className="dashboard">
					<Header onLogout={onLogout}>
						<Profile />
					</Header>
					<ConnectToConferenceBanner className="connect-conference">
						<Button color="primary">Подключиться к сессии</Button>
					</ConnectToConferenceBanner>
					<Block className="dashboard__content">{children}</Block>
					<MobileMenuContent onLogout={onLogout}>
						<Profile className="mobile-menu__links"/>
					</MobileMenuContent>
					<TelegramAlertBanner isOpened={isOpen}
						onClick={handleCloseTelegramBanner}
						onButtonClick={handleAddTelegram}
					/>
				</div>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default React.memo(ProfileBlock);
