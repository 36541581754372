import React from "react";

import {landingURL} from "@/shared/config/constants";
import {Button} from "@/shared/ui/uikit/Form";
import {Color, Typography} from "@/shared/ui/uikit/Typography";

interface Props {
	text: string
}

export const PrivacyAndPolicy: React.FC<Props> = ({text}) => {
	const goToTermOfSite = () => {
		window.open(
			`${landingURL}terms-of-use?termsType=clientsTerms`,
			"_blank"
		);
	};

	const goToPolicyAndPrivacy = () => {
		window.open(
			`${landingURL}terms-of-use?termsType=personalDataTerms`,
			"_blank"
		);
	};

	return (
		<Typography variant="sm">
			{text}, вы принимаете{" "}
			<Button variant="link" onClick={goToTermOfSite}>
				<Color color="primary">
					<u>«Условия использования сайта»</u>
				</Color>
			</Button>
			{" "} и {" "}
			<Button variant="link" onClick={goToPolicyAndPrivacy}>
				<Color color="primary">
					<u>«Политики о данных пользователей сайта»</u>
				</Color>
			</Button>
		</Typography>
	);
};
