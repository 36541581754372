import React from "react";
import ReactDOM from "react-dom";
import {CSSTransition} from "react-transition-group";

import WomanComputerIcon from "@/shared/ui/assets/emoji/womancomputer.png";
import {CloseFatSvg} from "@/shared/ui/assets/svg";
import {CustomButton} from "@/shared/ui/uikit/CustomButton/CustomButton";
import {Button} from "@/shared/ui/uikit/Form";
import {Block} from "@/shared/ui/uikit/Layout";

import "./telegram-alert-banner.scss";

interface TProps {
	onClick?: () => void
	isOpened?: boolean
	onButtonClick?: () => void
}

const TelegramAlertBanner: React.FC<TProps> = ({onClick, isOpened, onButtonClick}) => {
	const renderBanner = () => {
		return (
			<Block className="telegram-alert-banner">
				<Block className="telegram-alert-banner__wrapper">
					<Block className="telegram-alert-banner__icon-wrapper">
						<img className="telegram-alert-banner__icon" src={WomanComputerIcon} alt="emoji" />
					</Block>
					<h2 className="telegram-alert-banner__title">
						Добавьте в профиль свой<br />контакт в Telegram
					</h2>
					<p className="telegram-alert-banner__text">
						Это займёт не больше минуты!
					</p>
					<p className="telegram-alert-banner__text">
						Сохраните контакт с клиентами на случай отключения Skype в РФ. Добавьте в профиль своё Имя пользователя в Telegram.
					</p>
					<button className="telegram-alert-banner__btn telegram-alert-banner__btn--close" onClick={onClick}>
						<CloseFatSvg className="telegram-alert-banner__btn-icon" />
					</button>
					<Button color="primary" className="telegram-alert-banner__btn" onClick={onButtonClick}>
						Добавить Telegram
					</Button>
					<p className="telegram-alert-banner__text">
						Ваш конктакт в Telegram будет доступен только записавшимся к вам клиентам.
					</p>
				</Block>
			</Block>
		);
	};

	const _makePortal = () => {
		return (
			<>
				{ReactDOM.createPortal(renderBanner(), document && document.querySelector("body"))}
			</>
		);
	};

	return (
		<CSSTransition in={isOpened} timeout={200} classNames="custom-popup-" unmountOnExit>
			{_makePortal()}
		</CSSTransition>
	);
};

export default React.memo(TelegramAlertBanner);
